import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type DynamicConfig = {
  wsBrokerHost: string;
  keycloakUrl: string;
};

@Injectable({ providedIn: 'root' })
export class DynamicConfigService {
  constructor(private readonly http: HttpClient) {}

  getConfig(): Observable<DynamicConfig> {
    return this.http.get<DynamicConfig>('/assets/dynamic-config.json', {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
    });
  }
}
