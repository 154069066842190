import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig, TENANT_ID_LS_KEY } from './app/app.config';
import { AppComponent } from './app/app.component';
import { inject, runInInjectionContext } from '@angular/core';
import {
  DynamicConfig,
  DynamicConfigService,
} from '@vermo-core/services/dynamic-config.service';
import { firstValueFrom } from 'rxjs';

bootstrapApplication(AppComponent, appConfig)
  .then((appRef) => {
    const injector = appRef.injector;
    runInInjectionContext(injector, () => {
      const dynamicConfigService = inject(DynamicConfigService);
      firstValueFrom(dynamicConfigService.getConfig()).then((config) =>
        checkTenantExistence(config),
      );
    });
  })
  .catch((err) => console.error(err));

async function checkTenantExistence(dynamicConfig: DynamicConfig) {
  const path = window.location.pathname.replace('/', '').split('/');
  const potentialRealm = path[0];
  if (!sessionStorage.getItem(TENANT_ID_LS_KEY)) {
    return fetch(`${dynamicConfig.keycloakUrl}/realms/${potentialRealm}`)
      .then((res) => {
        if (sessionStorage.getItem(TENANT_ID_LS_KEY) !== potentialRealm) {
          sessionStorage.setItem(TENANT_ID_LS_KEY, potentialRealm);
          window.location.reload();
        }
      })
      .catch((err) => {
        if (sessionStorage.getItem(TENANT_ID_LS_KEY)) {
          sessionStorage.removeItem(TENANT_ID_LS_KEY);
          window.location.reload();
        }
      });
  }
}
